import consumer from "./consumer"

// consumer.subscriptions.create("AppChatChannel", {
  
//   connected() {
//     console.log('Chat Cable Connected');
//   },

//   disconnected() {
//   },

//   received(data) {
//     $('#app_toast').css('display', 'none');
//     $('#app_toast').text(data.message);
//     $('#app_toast').fadeIn('slow', () => {
//       $('#app_toast').delay(5000).fadeOut(); 
//     });
//   }

// });
