import consumer from "./consumer"

consumer.subscriptions.create("AppNotificationChannel", {
  
  connected() {
    // console.log('App Cable Connected');
  },

  disconnected() {
  },

  received(data) {
    $('#app_toast').css('display', 'none');
    $('#app_toast').text(data.message);
    $('#app_toast').fadeIn('slow', () => {
      $('#app_toast').delay(5000).fadeOut(); 
    });
  }

});
