// Action Cable provides the framework to deal with WebSockets in Rails.
// You can generate new channels where WebSocket features live using the `rails generate channel` command.

import { createConsumer } from "@rails/actioncable"

let socketHost = `ws://${window.location.host}/cable`;

const currentHostname = window.location.hostname;
if(currentHostname === "intents-service.voice-first.agency") {
    socketHost = 'wss://intents-service.voice-first.agency/cable';
} else if(currentHostname === "staff.poplarharca.co.uk" 
    || currentHostname === "intents-service-live-v2.voice-first.agency"
    || currentHostname === "myharca.poplarharca.co.uk"
    || currentHostname === "account.poplarharca.co.uk"
    || currentHostname === "repair.poplarharca.co.uk"
    || currentHostname === "forms.poplarharca.co.uk"
    || currentHostname === "chatbot-poppy.poplarharca.co.uk"
    || currentHostname === "poplarharca.local"
    || currentHostname === "poplarharca.tessellate.online"
    || currentHostname === "www.poplarharca.co.uk"
) {
    socketHost = 'wss://chatbot-poppy.poplarharca.co.uk/cable';
} else if(currentHostname === "intents-service-test.voice-first.agency" 
    || currentHostname === "home-test.myharca.co.uk" 
    || currentHostname === "test-chatbot-poppy.poplarharca.co.uk" 
    || currentHostname === "test-myharca.poplarharca.co.uk" 
    || currentHostname === "test-account.poplarharca.co.uk" 
    || currentHostname === "test-repair.poplarharca.co.uk" 
    || currentHostname === "test-form.poplarharca.co.uk" 
    || currentHostname === "test-staff.poplarharca.co.uk" 
    || currentHostname === "dev-staff.poplarharca.co.uk") {
    socketHost = 'wss://intents-service-test.voice-first.agency/cable';
}

export default createConsumer(socketHost);